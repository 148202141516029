<template>
    <admin-default v-slot:admin>
        <div class="application" v-if="partner">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Profile</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{ user.name }}</h1>
                    </div>
                </div>

<!--                <div class="profile__img&#45;&#45;box">-->
<!--                  <label for="images" style="cursor: pointer">-->
<!--                    <img :src="partner.image" alt="User-img" >-->
<!--                  </label>-->
<!--                  <input type="file" id="images" style="display: none">-->
<!--                </div>-->
            </div>

            <div class="purple">
                <div class="container container-resize">
                    <h1 class="text--white margin-bottom">Personal Information</h1>
                </div>
            </div>

            <div class="container container-resize">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="surname" class="form__label">Surname</label>
                            <input :value="partner.lastname" type="text"
                                   disabled
                                   class="form-control dis form__input margin-bottom"
                                   id="surname" placeholder="Idemudia">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="first" class="form__label">First Name</label>
                            <input :value="partner.name" type="text"
                                   class="form-control form__input form__input--small"
                                   disabled
                                   id="first" placeholder="Precious">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="middle" class="form__label">Middle Name</label>
                            <input :value="partner.maiden_name" disabled type="text" class="form-control form__input margin-bottom" id="middle" placeholder="Precious">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="birth" class="form__label">Date of Birth</label>
                            <input type="date" disabled :value="partner.birthday" class="form-control form__input margin-bottom" id="birth">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="marital" class="form__label">Marital Status</label>
                            <select class="form-control form__input form__input--small" id="marital" disabled>
                                <option :value="partner.maritalstatus">{{ partner.maritalstatus }}</option>
                                <option>Single</option>
                                <option>Divorce</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="gender" class="form__label">Gender</label>
                            <select class="form-control form__input margin-bottom" id="gender" disabled>
                                <option :value="partner.gender">{{ partner.gender }}</option>
                                <option>Female</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="code" class="form__label">Country Code</label>
                            <select class="form-control form__input form__input--small" id="code" disabled>
                                <option>(+234)</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="phone" class="form__label">Phone Number</label>
                            <input type="tel" :value="partner.mobile" disabled class="form-control form__input margin-bottom" id="phone">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Origin" class="form__label">State of Origin</label>
                            <select class="form-control form__input form__input--small" id="Origin" disabled>
                                <option>Delta</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="local" class="form__label">L.G.A</label>
                            <select class="form-control form__input margin-bottom" id="local" disabled>
                                <option>Isoko North</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="Country" class="form__label">Country</label>
                            <select class="form-control form__input margin-bottom" id="Country" disabled>
                                <option>{{ partner.country_id[1] }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="emails" class="form__label">Email Address</label>
                            <input disabled :value="partner.email" type="email" class="form-control form__input margin-bottom" id="emails" placeholder="example@gmail.com">
                        </div>
                    </div>
                </div>
            </div>

            <div class="purple">
                <div class="container container-resize">
                    <h1 class="text--white margin-bottom">Additional Information</h1>
                </div>
            </div>

            <div class="container container-resize">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="company" class="form__label">Company Name(If any)</label>
                            <input disabled :value="partner.company_name" type="text" class="form-control form__input form__input--small" id="company">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Email" class="form__label">Company Email(If any)</label>
                            <input disabled :value="partner.email" type="email" class="form-control form__input margin-bottom" id="Email">
                        </div>
                    </div>
                </div>

                <div class="button--box">
                    <button type="button" class="btn button button-cta cta">Save</button>
                    <button type="button" class="btn button btn-outline-primary button-outline cta">Reset</button>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
export default {
  name: "Profile",
  components: {AdminDefault},
  methods: {
    ...mapActions({
      PartnerUserUpdate: 'auth/PartnerUserUpdate'
    }),
    async LoadPartnerUserUpdate () {
      await this.PartnerUserUpdate({maiden_name: 'Sazzeb sam locos'})
    }
  },
  mounted() {
    this.LoadPartnerUserUpdate()
  },
  computed: {
    ...mapGetters({
      partner: 'auth/partner'
    })
  }
}
</script>

<style scoped>

</style>